/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Flex } from "@theme-ui/components"
import React from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share"

const ShareIcons = props => {
  const type = props.type || "page"
  const url = props.url || "https://www.bbc.co.uk"
  return (
    <Box>
      <h3>Share {type}</h3>
      <Flex>
        <EmailShareButton url={url}>
          <EmailIcon
            size={32}
            round
            sx={{ "& circle": { fill: "accent" }, mr: 1 }}
          />
        </EmailShareButton>

        <FacebookShareButton url={url}>
          <FacebookIcon
            size={32}
            round
            sx={{ "& circle": { fill: "accent" }, mr: 1 }}
          />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={url}>
          <FacebookMessengerIcon
            size={32}
            round
            sx={{ "& circle": { fill: "accent" }, mr: 1 }}
          />
        </FacebookMessengerShareButton>

        <TwitterShareButton url={url}>
          <TwitterIcon
            size={32}
            round
            sx={{ "& circle": { fill: "accent" }, mr: 1 }}
          />
        </TwitterShareButton>

        <WhatsappShareButton url={url}>
          <WhatsappIcon
            size={32}
            round
            sx={{ "& circle": { fill: "accent" }, mr: 1 }}
          />
        </WhatsappShareButton>
      </Flex>
    </Box>
  )
}

export default ShareIcons
